import { defineStore } from "pinia";

export const useWalletStore = defineStore("wallet", {
  state: () => {
    return {
      address: "",
      acc_short: "",
    };
  },

  actions: {
    saveWalletData(address: string) {
      this.address = address;
      this.acc_short = `${address.slice(0, 6)}...${address.slice(-6)}`;
    },
    clearWallet() {
      this.$reset();
    },
  },
});
