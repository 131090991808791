import Web3 from "web3";
import { AbiItem, Unit } from "web3-utils";
import { abi } from "./artifacts/contracts/Raffle.sol/Raffle.json";
import { Raffle } from "@/typechain/Raffle";

enum State {
  NotInitialized,
  Initialized,
  Created,
  Live,
  Paused,
  Finished,
  Redeemed,
}

export class RaffleService {
  constructor(private web3Client: Web3) {
    console.log("New RaffleService initialised");
  }

  getBalance = async (
    address: string,
    unit: Unit
  ): Promise<string | undefined> => {
    const balance = await this.web3Client.eth.getBalance(address);
    return Web3.utils.fromWei(balance, unit);
  };

  getNetwork = async (): Promise<string | undefined> => {
    const network = await this.web3Client.eth.getChainId();
    console.log(`Network chain: ${network}`);
    switch (network) {
      case 1:
        return "Mainnet";
      case 2:
        return "Morden";
      case 3:
        return "Ropsten";
      case 4:
        return "Rinkeby";
      case 42:
        return "Kovan";
      case 137:
        return "Polygon Mainnet";
      case 31337:
        return "Localhost";
      case 80001:
        return "Polygon Mumbai";
      default:
        return "Unknown";
    }
  };

  getLoggedAccount = async (): Promise<string | undefined> => {
    const accounts = await this.web3Client.eth.getAccounts();

    return accounts && accounts.length > 0 ? accounts[0] : undefined;
  };

  enterRaffle = async (address: string, amount: number): Promise<boolean> => {
    const accounts = await this.web3Client.eth.getAccounts();
    const contract = (await new this.web3Client.eth.Contract(
      abi as AbiItem[],
      address
    )) as unknown as Raffle;

    const entry = await contract.methods
      .enterRaffle(amount)
      .send({ from: accounts[0] });
    return entry.status;
  };

  getState = async (address: string): Promise<string | boolean> => {
    try {
      const contract = (await new this.web3Client.eth.Contract(
        abi as AbiItem[],
        address
      )) as unknown as Raffle;
      const state = await contract.methods.getState().call();

      return State[Number(state)];
    } catch (e) {
      console.log(getErrorMessage(e));
      return false;
    }
  };

  getRaffleBalance = async (
    address: string,
    unit: Unit
  ): Promise<string | boolean> => {
    try {
      const contract = (await new this.web3Client.eth.Contract(
        abi as AbiItem[],
        address
      )) as unknown as Raffle;
      const balance = await contract.methods.getBalance().call();
      return Web3.utils.fromWei(balance, unit);
    } catch (e) {
      console.log(getErrorMessage(e));
      return false;
    }
  };

  getMaxTickets = async (address: string): Promise<string | boolean> => {
    try {
      const contract = (await new this.web3Client.eth.Contract(
        abi as AbiItem[],
        address
      )) as unknown as Raffle;
      const maxTickets = await contract.methods.maxTickets().call();
      console.log(maxTickets);
      return maxTickets;
    } catch (e) {
      console.log(getErrorMessage(e));
      return false;
    }
  };

  getSoldTickets = async (address: string): Promise<string | boolean> => {
    try {
      const contract = (await new this.web3Client.eth.Contract(
        abi as AbiItem[],
        address
      )) as unknown as Raffle;
      const soldTickets = await contract.methods.getSoldTickets().call();
      console.log("Sold tickets: " + soldTickets);
      return soldTickets;
    } catch (e) {
      console.log(getErrorMessage(e));
      return false;
    }
  };

  getOwnTickets = async (address: string): Promise<string | boolean> => {
    try {
      const contract = (await new this.web3Client.eth.Contract(
        abi as AbiItem[],
        address
      )) as unknown as Raffle;
      const ownTickets = await contract.methods.getOwnTickets().call();
      console.log("Own tickets: " + ownTickets);
      return ownTickets;
    } catch (e) {
      console.log(getErrorMessage(e));
      return false;
    }
  };

  getEndDate = async (address: string): Promise<string | boolean> => {
    try {
      const contract = (await new this.web3Client.eth.Contract(
        abi as AbiItem[],
        address
      )) as unknown as Raffle;
      const endDate = await contract.methods.endDate().call();
      console.log("End date: " + endDate);
      return endDate;
    } catch (e) {
      console.log(getErrorMessage(e));
      return false;
    }
  };

  getToken = async (
    address: string
  ): Promise<{ token: string; id: string } | boolean> => {
    try {
      const contract = (await new this.web3Client.eth.Contract(
        abi as AbiItem[],
        address
      )) as unknown as Raffle;
      const token = await contract.methods.token().call();
      const id = await contract.methods.id().call();
      console.log("Token: " + token);
      console.log("Id: " + id);
      return {
        token,
        id,
      };
    } catch (e) {
      console.log(getErrorMessage(e));
      return false;
    }
  };

  getErc20 = async (address: string): Promise<string | boolean> => {
    try {
      const contract = (await new this.web3Client.eth.Contract(
        abi as AbiItem[],
        address
      )) as unknown as Raffle;
      const token = await contract.methods.erc20Token().call();
      //const id = await contract.methods.id().call();
      console.log("ERC20 Token address: " + token);
      //console.log("Id: " + id);
      return token;
    } catch (e) {
      console.log(getErrorMessage(e));
      return false;
    }
  };

  getTicketPrice = async (
    address: string,
    unit: Unit
  ): Promise<string | boolean> => {
    try {
      // const accounts = await web3.eth.getAccounts();
      const contract = (await new this.web3Client.eth.Contract(
        abi as AbiItem[],
        address
      )) as unknown as Raffle;
      const ticketPrice = await contract.methods.getTicketPrice().call();
      console.log(ticketPrice);
      return Web3.utils.fromWei(ticketPrice, unit);
    } catch (e) {
      console.log(getErrorMessage(e));
      return false;
    }
  };

  getEnoughLink = async (address: string): Promise<boolean> => {
    try {
      // const accounts = await web3.eth.getAccounts();
      const contract = (await new this.web3Client.eth.Contract(
        abi as AbiItem[],
        address
      )) as unknown as Raffle;
      return await contract.methods.enoughLINK().call();
    } catch (e) {
      console.log(getErrorMessage(e));
      //TODO: return something different here
      return false;
    }
  };

  getRaffleOwner = async (address: string): Promise<string | boolean> => {
    try {
      // const accounts = await web3.eth.getAccounts();
      const contract = (await new this.web3Client.eth.Contract(
        abi as AbiItem[],
        address
      )) as unknown as Raffle;
      const owner = await contract.methods.owner().call();
      console.log("Owner: " + owner);
      return owner;
    } catch (e) {
      console.log(getErrorMessage(e));
      return false;
    }
  };

  getRaffle = async (address: string): Promise<Raffle | boolean> => {
    try {
      return (await new this.web3Client.eth.Contract(
        abi as AbiItem[],
        address
      )) as unknown as Raffle;
    } catch (e) {
      console.log(getErrorMessage(e));
      return false;
    }
  };
}

function getErrorMessage(error: unknown) {
  if (error instanceof Error) return error.message;
  return String(error);
}
