<template>
  <div>
    <div
      id="loadContract"
      class="d-flex justify-content-center align-items-center"
    >
      <div class="text-center" style="width: 60%">
        <button
          type="button"
          class="btn btn-primary mb-4"
          @click="createContract"
        >
          Create Raffle contract
        </button>
        <hr />
        <input
          id="address"
          v-model="address"
          type="text"
          placeholder="Existing contract.."
          class="mr-3 mt-4"
        /><button
          type="button"
          class="btn btn-primary"
          @click="loadContract(address)"
        >
          Load
        </button>
        <div class="card">
          <a
            href="javascript:void(0)"
            @click="loadContract('0xDc64a140Aa3E981100a9becA4E685f962f0cF6C9')"
            >0xDc64a140Aa3E981100a9becA4E685f962f0cF6C9</a
          >
          <br />
          <a
            href="javascript:void(0)"
            @click="loadContract('0x0165878a594ca255338adfa4d48449f69242eb8f')"
            >0x0165878a594ca255338adfa4d48449f69242eb8f</a
          >
        </div>
      </div>
    </div>
    <div
      v-if="loading"
      class="loading d-flex justify-content-center align-items-center"
    >
      <div class="spinner-border" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { RaffleService } from "@/domain/Raffle";
import Web3client from "@/domain/web3";

export default {
  name: "RaffleLoad",
  data: function () {
    return {
      address: "",
      loading: false,
    };
  },
  methods: {
    createContract: async function () {
      const web3 = await Web3client();
      if (!web3) {
        throw new Error("Web3 could not be created");
      }
      const raffleService = new RaffleService(web3);
      this.loading = true;
      try {
        const contract = await raffleService.createContract();
        this.loadContract(contract.options.address);
      } catch (e) {
        console.log(e);
      }
      this.loading = false;
    },
    loadContract: function (address) {
      console.log(`Load new contract ${address}`);
      this.$store.commit("setContract", address);
    },
  },
};
</script>

<style scoped>
#address {
  width: 400px;
  height: 32px;
}
#loadContract {
  margin-top: 6em;
}
</style>
