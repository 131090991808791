import Web3 from "web3";
import { abi } from "./artifacts/@openzeppelin/contracts/token/ERC20/ERC20.sol/ERC20.json";
import { AbiItem, toBN } from "web3-utils";
import { ERC20 } from "@/typechain/ERC20";

export class ERC20Service {
  constructor(private web3Client: Web3, private tokenAddress: string) {
    console.log(
      `New ERC20Service initialised for token address: ${tokenAddress}`
    );
  }

  async getBalance(walletAddress: string): Promise<string | boolean> {
    try {
      const contract = (await new this.web3Client.eth.Contract(
        abi as AbiItem[],
        this.tokenAddress
      )) as unknown as ERC20;
      const balance = await contract.methods.balanceOf(walletAddress).call();
      //const id = await contract.methods.id().call();
      console.log(
        `ERC20 Token balance for address ${walletAddress}: ${balance} token`
      );
      //console.log("Id: " + id);
      return balance;
    } catch (e) {
      console.log(getErrorMessage(e));
      return false;
    }
  }

  async getDecimals(): Promise<string | boolean> {
    try {
      const contract = (await new this.web3Client.eth.Contract(
        abi as AbiItem[],
        this.tokenAddress
      )) as unknown as ERC20;
      const decimals = await contract.methods.decimals().call();
      console.log(`ERC20 Token uses ${decimals} decimals`); //const id = await contract.methods.id().call();
      return decimals;
    } catch (e) {
      console.log(getErrorMessage(e));
      return false;
    }
  }

  async approve(approvedAddress: string, amount: number): Promise<boolean> {
    const accounts = await this.web3Client.eth.getAccounts();
    const contract = (await new this.web3Client.eth.Contract(
      abi as AbiItem[],
      this.tokenAddress
    )) as unknown as ERC20;
    console.log(
      `ERC20 Token approval for address ${approvedAddress}: ${amount} token`
    );

    const bnAmount = toBN(amount);
    const approval = await contract.methods
      .approve(approvedAddress, bnAmount)
      .send({ from: accounts[0] });

    console.log(`Approval ${approval.status}`);
    return approval.status;
  }
}

function getErrorMessage(error: unknown) {
  if (error instanceof Error) return error.message;
  return String(error);
}
