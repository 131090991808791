import Web3 from "./web3";
import { AbiItem } from "web3-utils";
import { Contract } from "web3-eth-contract";
import {
  abi,
  bytecode,
} from "./artifacts/contracts/test/NFT.sol/FactoryNFT.json";
import { FactoryNFT } from "@/typechain/FactoryNFT";
import { TransactionReceipt } from "web3-core/types";

export class NFTService {
  createContract = async (): Promise<FactoryNFT | Contract | undefined> => {
    const web3 = await Web3();
    if (!web3) {
      return undefined;
    }

    const accounts = await web3.eth.getAccounts();
    const contract = await new web3.eth.Contract(abi as AbiItem[])
      .deploy({
        data: bytecode,
      })
      .send({ from: accounts[0] });
    console.log(contract);
    return contract;
  };

  mintNFT = async (
    address: string,
    tokenURI: string
  ): Promise<TransactionReceipt | string | undefined> => {
    const web3 = await Web3();
    if (!web3) {
      return undefined;
    }

    let error;
    try {
      const accounts = await web3.eth.getAccounts();
      const contract = (await new web3.eth.Contract(
        abi as AbiItem[],
        address
      )) as unknown as FactoryNFT;
      return await contract.methods
        .mintNFT(accounts[0], tokenURI)
        .send({ from: accounts[0] });
    } catch (e) {
      error = (e as Error).message;
    }

    return error;
  };

  getTokenMetadata = async (
    address: string,
    tokenId: string
  ): Promise<TransactionReceipt | string | undefined> => {
    const web3 = await Web3();
    if (!web3) {
      return undefined;
    }

    let error;
    try {
      const accounts = await web3.eth.getAccounts();
      const contract = (await new web3.eth.Contract(
        abi as AbiItem[],
        address
      )) as unknown as FactoryNFT;
      const tx = await contract.methods
        .tokenURI(tokenId)
        .call({ from: accounts[0] });
      console.log(tx);
      return tx;
    } catch (e) {
      error = (e as Error).message;
    }

    return error;
  };

  getTokenOwner = async (
    address: string,
    tokenId: string
  ): Promise<TransactionReceipt | string | undefined> => {
    const web3 = await Web3();
    if (!web3) {
      return undefined;
    }

    let error;
    try {
      const accounts = await web3.eth.getAccounts();
      const contract = (await new web3.eth.Contract(
        abi as AbiItem[],
        address
      )) as unknown as FactoryNFT;
      return await contract.methods
        .ownerOf(tokenId)
        .call({ from: accounts[0] });
    } catch (e) {
      error = (e as Error).message;
    }

    return error;
  };

  transferNFT = async (
    contractAddress: string,
    toAddress: string,
    tokenId: string
  ): Promise<TransactionReceipt | string | undefined> => {
    const web3 = await Web3();
    if (!web3) {
      return undefined;
    }

    let error;
    try {
      const accounts = await web3.eth.getAccounts();
      const contract = (await new web3.eth.Contract(
        abi as AbiItem[],
        contractAddress
      )) as unknown as FactoryNFT;
      return await contract.methods[
        "safeTransferFrom(address,address,uint256)"
      ](accounts[0], toAddress, tokenId).send({ from: accounts[0] });
    } catch (e) {
      error = (e as Error).message;
    }

    return error;
  };

  getNetwork = async (): Promise<string | undefined> => {
    const web3 = await Web3();
    if (!web3) {
      return undefined;
    }
    const network = await web3.eth.getChainId();
    console.log(network);
    switch (network) {
      case 1:
        return "Mainnet";
      case 2:
        return "Morden";
      case 3:
        return "Ropsten";
      case 4:
        return "Rinkeby";
      case 42:
        return "Kovan";
      case 31337:
        return "Localhost";
      default:
        return "Unknown";
    }
  };

  getLoggedAccount = async function (): Promise<string | undefined> {
    const web3 = await Web3();
    if (!web3) {
      return undefined;
    }
    const accounts = await web3.eth.getAccounts();

    return accounts && accounts.length > 0 ? accounts[0] : undefined;
  };
}
