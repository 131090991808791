<template>
  <main id="main">
    <!-- entry content area -->
    <section class="entry-holder">
      <h2>ENTER NOW TO WIN CRYPTO PUNK WORTH Ξ300 ($1.5M)</h2>
      <div class="row">
        <div class="col-12 col-md-7">
          <div class="info-card" @click="reloadState">
            <ul class="list">
              <li>
                <span>Token ID</span>
                <span>{{ nftContract.id || "N/A" }}</span>
              </li>
              <li>
                <span>Contract</span>
                <span>{{
                  nftContract.token
                    ? `${nftContract.token.slice(
                        0,
                        8
                      )}...${nftContract.token.slice(-8)}`
                    : "N/A"
                }}</span>
              </li>
              <li>
                <span>Blockchain</span>
                <span>{{ network }}</span>
              </li>
              <li>
                <span>Token Standard</span>
                <span>ERC721</span>
              </li>
              <li>
                <span>{{
                  maxTickets
                    ? `This raffle has a maximum of ${maxTickets} sold tickets`
                    : `There is no maximum of sold tickets`
                }}</span>
              </li>
            </ul>
          </div>
          <div class="timer-holder">
            <h3>JOIN DRAW</h3>
            <div id="periodic-timer_period_days"></div>
          </div>
        </div>
        <div class="col-12 col-md-5">
          <div class="card">
            <div class="img-holder">
              <img src="../../assets/images/img.jpg" alt="" />
            </div>
          </div>
        </div>
      </div>
      <div class="buy-cards">
        <div class="row">
          <div class="col-12 col-md-6 col-lg-4">
            <div class="info-card">
              <h3>
                5 <br />
                ENTRIES
              </h3>
              <hr />
              <span class="value">5 USDC</span>
              <span class="value-unit">(Ξ 0.0019)</span>
              <span class="btn btn-primary large" @click="buyTickets(5)"
                >BUY NOW</span
              >
            </div>
          </div>
          <div class="col-12 col-md-6 col-lg-4">
            <div class="info-card">
              <h3>
                20 <br />
                ENTRIES
              </h3>
              <hr />
              <span class="value">20 USDC</span>
              <span class="value-unit">(Ξ 0.0076)</span>
              <span class="btn btn-primary large" @click="buyTickets(20)"
                >BUY NOW</span
              >
            </div>
          </div>
          <div class="col-12 col-md-6 col-lg-4">
            <div class="info-card">
              <h3>
                50 <br />
                ENTRIES
              </h3>
              <hr />
              <span class="value">50 USDC</span>
              <span class="value-unit">(Ξ 0.019)</span>
              <span class="btn btn-primary large" @click="buyTickets(50)"
                >BUY NOW</span
              >
            </div>
          </div>
        </div>
      </div>
      <div class="amount-cards">
        <h3>ANY AMOUNT</h3>
        <div class="info-card">
          <div class="row">
            <div class="col-12 col-md-8">
              <div class="amount-detail">
                <img src="../../assets/images/usdc.svg" alt="" />
                <div class="amount-data">
                  <span class="amount">USDC</span>
                  <span class="amount">$30</span>
                  <span class="cost">(Ξ 0.021370)</span>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-4 align-right">
              <a href="#" class="btn btn-primary large">BUY NOW</a>
            </div>
          </div>
        </div>
      </div>
      <div class="entrants-holder">
        <h3>OTHER ENTRANTS</h3>
        <div class="info-card">
          <div class="table-holder">
            <table>
              <thead>
                <th>ENTRIES</th>
                <th>WALLET</th>
                <th>DATE</th>
              </thead>
              <tbody>
                <td>150</td>
                <td>Vx23433</td>
                <td>2/2/2022</td>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
/* eslint-disable */
import { RaffleService } from "@/domain/Raffle";
import { fromWei, isAddress } from "web3-utils";
import { mapStores } from "pinia";
import { useWeb3Store } from "@/stores/web3";
import { useWalletStore } from "@/stores/wallet";
import { ERC20Service } from "@/domain/ERC20";

export default {
  props: ["raffleAddress"],
  data() {
    return {
      balance: null,
      ticketPrice: null,
      tickets: null,
      maxTickets: null,
      ownTickets: null,
      endDate: null,
      raffleOwner: "",
      nftContract: {},
      state: "",
      network: "",
      logged: "",
      loading: false,
    };
  },
  computed: {
    ...mapStores(useWeb3Store, useWalletStore),
    raffleContractLink: function () {
      if (this.$store.state.contract) {
        switch (this.network) {
          case "Polygon Mumbai":
            return (
              "https://mumbai.polygonscan.com/address/" +
              this.$store.state.contract
            );
        }
      }
      return "";
    },
    isValidEthAddress() {
      return isAddress(this.raffleAddress);
    },
  },
  created: async function () {
    console.log("RaffleDetail view created");
    console.log(
      `Is "${this.raffleAddress}" valid ETH address? ${this.isValidEthAddress}`
    );

    if (this.isValidEthAddress && this.web3Store.isInitialised)
      await this.reloadState();
  },

  methods: {
    async reloadState() {
      if (!this.isValidEthAddress || !this.web3Store.isInitialised) {
        throw new Error(
          "Cannot refresh state, either invalid raffle address or web3 not initialised"
        );
      }

      const raffleService = new RaffleService(this.web3Store.provider);
      this.network = await raffleService.getNetwork();

      if (this.isValidEthAddress && this.web3Store.isInitialised) {
        this.state = await raffleService.getState(this.raffleAddress);
        console.log(`Current state: ${this.state}`);
      }

      if (this.state === "Live") {
        this.balance = await raffleService.getRaffleBalance(
          this.raffleAddress,
          "ether"
        );
        this.ticketPrice = await raffleService.getTicketPrice(
          this.raffleAddress,
          "wei"
        );
        this.maxTickets = await raffleService.getMaxTickets(this.raffleAddress);
        this.nftContract = await raffleService.getToken(this.raffleAddress);

        this.soldTickets = await raffleService.getSoldTickets(
          this.raffleAddress
        );
        this.ownTickets = await raffleService.getOwnTickets(this.raffleAddress);
        this.endDate = await raffleService.getEndDate(this.raffleAddress);

        this.erc20Token = await raffleService.getErc20(this.raffleAddress);
      }
    },
    async buyTickets(amount) {
      if (!this.isValidEthAddress || !this.web3Store.isInitialised) {
        throw new Error(
          "Cannot buy tickets, either invalid raffle address or web3 not initialised"
        );
      }

      const tokenValue = amount * this.ticketPrice;

      console.log(`buying ${amount} tickets for ${tokenValue} ERC20 token`);

      const raffleService = new RaffleService(this.web3Store.provider);
      const erc20Service = new ERC20Service(
        this.web3Store.provider,
        this.erc20Token
      );
      const ownAddress = this.walletStore.address;

      this.loading = true;
      try {
        const balance = await erc20Service.getBalance(ownAddress);

        console.log(`Current own balance: ${balance}`);
        console.log(`Price total: ${tokenValue}`);
        if (balance < tokenValue) {
          throw new Error("Not enough funds to buy tickets");
        }

        const transactions = await Promise.all([
          await erc20Service.approve(this.raffleAddress, tokenValue),
          await raffleService.enterRaffle(this.raffleAddress, amount),
        ]);

        console.log(transactions);
      } catch (e) {
        alert(e.message);
      }
      this.loading = false;
    },

    fromWei: fromWei,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
