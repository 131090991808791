<template>
  <!-- header of the page -->
  <header id="header">
    <div class="container">
      <!-- brand logo -->
      <div class="logo">
        <router-link to="/">
          <img src="@/assets/images/logo.svg" alt="" />
        </router-link>
      </div>
      <div id="nav-opener">
        <span></span>
        <span></span>
        <span></span>
      </div>
      <!-- header right content -->
      <div class="header-holder">
        <nav id="nav">
          <ul>
            <li>
              <router-link to="/raffle/latest">Current Raffle</router-link>
            </li>
          </ul>
        </nav>
        <a href="#" @click="connectWallet" class="btn btn-primary">
          <span class="">{{
            walletStore.address
              ? `📡✅ ${walletStore.acc_short}`
              : `📡❌ CONNECT WALLET`
          }}</span></a
        >
      </div>
    </div>
  </header>
</template>

<script>
import { mapStores } from "pinia";
import { Options, Vue } from "vue-class-component";

import RaffleLoader from "@/components/RaffleLoader";
import RaffleDetail from "@/components/raffle/RaffleDetail";
import NFTFactory from "@/components/NFTFactory";
import { useWeb3Store } from "@/stores/web3";
import { useWalletStore } from "@/stores/wallet";

@Options({
  name: "Header",
  components: {
    RaffleLoader,
    RaffleDetail,
    NFTFactory,
  },
  computed: {
    ...mapStores(useWeb3Store, useWalletStore),
  },
  methods: {
    async connectWallet() {
      try {
        const connected = await this.web3Store.setWeb3Provider();

        this.$notify(
          connected
            ? {
                title: "Connected to Wallet",
                text: `Nice, you've connected your wallet with address ${this.walletStore.address}`,
                duration: 3000,
                type: "success",
              }
            : {
                title: "Could not connect to Wallet",
                text: `Damn, something went wrong. Do you have Metamask installed?`,
                type: "error",
                duration: 5000,
              }
        );
      } catch (e) {
        console.error(e);
      }
    },
  },
})
export default class Header extends Vue {}
</script>

<style scoped></style>
