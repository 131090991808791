import { defineStore } from "pinia";
import Web3 from "web3";
import Web3client from "@/domain/web3";
import { useWalletStore } from "@/stores/wallet";

interface State {
  web3Provider: Web3;
  isInitialised: boolean;
}

export const useWeb3Store = defineStore("web3", {
  state: (): State =>
    <State>{
      web3Provider: {},
      isInitialised: false,
    },
  getters: {
    provider(state) {
      return state.web3Provider;
    },
  },
  actions: {
    async setWeb3Provider(): Promise<boolean> {
      console.debug("Connecting web3provider");
      const walletStore = useWalletStore();
      if (this.isInitialised) {
        console.log("Provider is already set");
        return true;
      }

      const web3 = await Web3client();
      if (!web3) {
        throw new Error("Web3 could not be created");
      }

      this.web3Provider = web3;
      this.isInitialised = true;

      const accounts = await web3.eth.getAccounts();
      walletStore.saveWalletData(accounts[0]);

      return true;
    },
    clearWeb3() {
      this.$reset();
    },
  },
});
