<template>
  <div class="card" style="width: 50%">
    <div class="card-body">
      <h2 class="card-title">NFT Loader</h2>
      <h6 class="card-subtitle mb-2 text-muted">
        Loaded contract:
        <span v-if="nftContractAddress">
          <span v-if="nftContractLink">
            <a :href="nftContractLink">{{ nftContractAddress }}</a>
          </span>
          <span v-if="!nftContractLink">{{ nftContractAddress }}</span>
        </span>
        <span v-if="!nftContractAddress">N/A</span>
      </h6>

      <input
        id="nftContractAddressField"
        v-model="nftContractAddressField"
        type="text"
        placeholder="Existing NFT contract.."
        class="mr-3 mt-4"
      />

      <!--        <p class="card-text mt-4">Current sold Tickets: {{ state }}</p>-->
      <!--        <p class="card-text mt-4">Raffle End date: {{ state }}</p>-->

      <!--        <button type="button" class="btn btn-primary" @click="withdraw">-->
      <!--          Withdraw-->
      <!--        </button>-->
      <button
        type="button"
        class="btn btn-primary mr-3"
        @click="setNFTAddress(nftContractAddressField)"
        v-if="
          nftContractAddressField &&
          nftContractAddressField !== nftContractAddress
        "
      >
        Load NFT Contract
      </button>

      <button
        type="button"
        class="btn btn-primary mr-3"
        @click="createNewNftFactory"
      >
        New NFT Contract
      </button>

      <button
        type="button"
        class="btn btn-danger"
        v-if="nftContractAddress.length"
        @click="mintNFT"
      >
        Mint NFT
      </button>
      <div>
        <input
          id="tickets"
          v-if="nftContractAddress.length"
          v-model="tokenId"
          type="number"
          placeholder="TokenId"
          class="mr-3 mt-4"
        />

        <button
          type="button"
          class="btn btn-primary"
          v-if="nftContractAddress.length"
          @click="loadNFT(tokenId)"
        >
          Load NFT infos
        </button>
      </div>

      <a
        href="javascript:void(0)"
        @click="setNFTAddress('0x8464135c8f25da09e49bc8782676a84730c318bc')"
        >0x8464135c8f25da09e49bc8782676a84730c318bc</a
      >
      <br />
      <a
        href="javascript:void(0)"
        @click="setNFTAddress('0x0165878a594ca255338adfa4d48449f69242eb8f')"
        >0x0165878a594ca255338adfa4d48449f69242eb8f</a
      >
    </div>
  </div>
  <div class="card" v-if="metadata.attributes">
    <div class="card-body">
      <h2 class="card-title">NFT Details</h2>
      <div><strong>Name:</strong> {{ metadata.name }}</div>
      <div class="mb-3">
        <strong>Description:</strong> {{ metadata.description }}
      </div>

      <img
        :src="metadata.image"
        v-if="metadata.image"
        class="box mr-5"
        alt="NFT Image"
      />

      <pre class="box box-wide" v-if="metadata">{{
        JSON.stringify(metadata, null, 2)
      }}</pre>

      <button
        type="button"
        class="btn btn-primary"
        v-if="isOwnToken"
        @click="deposit()"
      >
        Transfer token to Raffle contract
      </button>

      <div class="mt-3">
        TokenID: {{ this.tokenId }}, Owner:
        {{ this.isOwnToken ? "You" : this.tokenOwner }}
      </div>
    </div>
  </div>
  <div
    v-if="loading"
    class="loading d-flex justify-content-center align-items-center"
  >
    <div class="spinner-border" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { NFTService } from "@/domain/NFT";

export default {
  data() {
    return {
      nftContractAddressField: "",
      nftContract: {},
      nft: {},
      metadata: {},
      tokenId: 1,
      tokenOwner: "",
      tokenUri: "",
      state: "",
      network: "",
      logged: "",
      loading: false,
      nftService: "",
      ownAccount: "",
    };
  },
  computed: {
    raffleAddress: function () {
      return this.$store.state.contract;
    },
    nftContractAddress: function () {
      return this.$store.state.nftContractAddress;
    },
    isOwnToken: function () {
      return this.tokenOwner === this.ownAccount;
    },
    nftContractLink: function () {
      if (this.$store.state.nftContractAddress) {
        switch (this.network) {
          case "Polygon Mumbai":
            return (
              "https://mumbai.polygonscan.com/address/" +
              this.$store.state.nftContractAddress
            );
        }
      }
      return "";
    },
  },
  created: async function () {
    this.nftService = new NFTService();
    this.network = await this.nftService.getNetwork();
    this.ownAccount = await this.nftService.getLoggedAccount();
  },
  methods: {
    createNewNftFactory: async function () {
      const address = this.$store.state.contract;
      const nftService = new NFTService();
      if (address) {
        this.loading = true;
        try {
          this.nftContract = await nftService.createContract();
          this.setNFTAddress(this.nftContract.options.address);
        } catch (e) {
          console.log(e);
        }
        this.loading = false;
      }
    },
    setNFTAddress: function (address) {
      console.log(`New NFT address set ${address}`);
      this.nftContractAddressField = "";
      this.$store.commit("setNFTContractAddress", address);
    },
    loadNFT: async function (tokenId) {
      if (this.nftContractAddress) {
        this.loading = true;
        try {
          this.tokenUri = await this.nftService.getTokenMetadata(
            this.nftContractAddress,
            tokenId
          );
          console.log(`Got tokenUri from id ${tokenId}, ${this.tokenUri}`);

          this.tokenOwner = await this.nftService.getTokenOwner(
            this.nftContractAddress,
            tokenId
          );
          console.log(this.tokenOwner);
          console.log(`Is own NFT ${this.tokenOwner === this.ownAccount}`);

          const tokenURL = new URL(this.tokenUri);
          const request = await fetch(
            "http://localhost:8080" + tokenURL.pathname,
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          );
          this.metadata = await request.json();
        } catch (e) {
          console.log(e);
        }
        this.loading = false;
      }
    },
    mintNFT: async function () {
      const metadata = `https://opensea-creatures-api.herokuapp.com/api/creature/${Math.floor(
        Math.random() * 2000
      )}`;
      const nftService = new NFTService();

      if (this.nftContractAddress) {
        this.loading = true;
        try {
          const tx = await nftService.mintNFT(
            this.nftContractAddress,
            metadata
          );
          this.tokenId = tx.events.Transfer.returnValues.tokenId;

          alert(
            `New NFT crafted with tokenId ${tx.events.Transfer.returnValues.tokenId}`
          );
        } catch (e) {
          console.log(e);
        }
        this.loading = false;
      }
    },
    deposit: async function () {
      if (!this.nft) {
        return alert("Abort: No NFT is loaded");
      }
      if (!this.isOwnToken) {
        return alert("Abort: Loaded NFT is not owned by you");
      }

      if (this.isOwnToken) {
        this.loading = true;
        try {
          console.log(
            `transferring from contract ${this.nftContractAddress} to ${this.raffleAddress}, id ${this.tokenId}`
          );
          const transfer = await this.nftService.transferNFT(
            this.nftContractAddress,
            this.raffleAddress,
            this.tokenId
          );
          console.log(transfer);
        } catch (e) {
          console.log(e);
        }
        this.loading = false;
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
img {
  max-width: 100%;
  max-height: 100%;
  display: block; /* remove extra space below image */
}

.box {
  width: 250px;
  height: 250px;
  float: left;
  overflow: auto;
}

.box-wide {
  width: unset;
}

h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
